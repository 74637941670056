@font-face {
    font-family: "Exo2-Bold";
    src: local("Exo2-Bold"),
     url("../fonts/Exo2-VariableFont_wght.ttf") format("truetype");
    /* font-weight: 100; */
}

.agsbutton{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;

    font-family: "Exo2-Bold";
    font-size: 15px;
    font-weight: 100;
    color: white;

    border-radius: 25px;
    width: 130px;
    height: 30px;
    border: 0px;
    margin: 0px 10px;
    padding: 0px 10px 2px;
    background: rgb(55, 136, 241);
    
    cursor: pointer;
    transition: opacity 0.25s ease-out;
}

.agsbutton:hover{
    opacity: 0.8;
}