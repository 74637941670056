@font-face {
    font-family: "Exo2-Bold";
    src: local("Exo2-Bold"),
     url("../fonts/Exo2-VariableFont_wght.ttf") format("truetype");
    /* font-weight: 100; */
}

.agsFloatingBoxes{
    border: 1px solid rgb(214, 214, 214);
    border-radius: 15px;
    padding: 20px 20px;
    box-shadow: 4px 4px 10px #e5e5e5;
    max-height: 80%;
    background: #FFFFFF;
    margin: 20px;
}

.agslinkText{
    font-family: "Exo2-Bold";
    font-size: 15px;
    cursor: pointer;
    border-bottom: 1px dotted #000;
}
.agslinkText:hover{
    color: blue;
}

.agsErrorText{
    font-family: "Exo2-Bold";
    font-size: 12px;
    left: 20px;
    color: crimson;
    position: absolute;
    font-style: italic;
}

.trainCards{
    border: 1px solid rgb(159, 155, 155);
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    word-wrap: break-word;
    font-family: "Exo2-Bold";
    font-size: 16px;
    box-shadow: 5px 1px 5px rgb(202, 202, 202);
}

